<template>
	<div class="flex justify-center">
		<profileComponent
			:userSettings="userSettings">
		
		</profileComponent>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

// Components
// const profileComponent = import('@/views/profiles/components/profile-component')
import profileComponent from '@/views/profiles/components/profile-component'
import LoadingState from '@/components/component-management/loading-state-component.vue'
import ErrorState from '@/components/component-management/error-state-component.vue'

export default {
  name: 'profileView',
  components: {
    profileComponent
  },
  mixins: [
  ],
  props: {

  },
  data () {
    return {
			// Main Data
			userSettings: {
				ref: 'adminFormRef'
			},
			profileData: {}

    }
  },
  methods: {
    testButton () {

    }

  },
  computed: {
    ...mapState(["Auth"]),
    ...mapGetters(["GET_ADMIN_PROFILE"])

	},
	created() {
	// 	this.$set(this, 'profileData', this.GET_ADMIN_PROFILE)
		// this.profileData = this.GET_ADMIN_PROFILE
	},
  mounted () {

  }
}
</script>

<style scoped lang="scss">

</style>
