<template>
	<div class="flex justify-center">
		<div class="container mt-4">
				<!-- Main Container Row -->
				<div class="row">
					<!-- Main Content Area -->
					<div class="col-12 px-5">
						<DxScrollView
							:scroll-by-content="true"
							:scroll-by-thumb="true"
							show-scrollbar="onHover"
							height="90vh"
						>

							<!-- View Form Fields -->
							<template v-if="!editProfile">
								<v-card>
									<v-card-title>
										<div class="row">
											<div class="col-9">
												<div class="headline-2">
													Profile Details
												</div>
											</div>
											<div class="col-3 text-right">
												<div class="offset-header-buttons">
													<v-btn class="mx-2" fab dark color="accent" @click="editProfile = !editProfile">
														<v-icon dark>mdi-pencil</v-icon>
													</v-btn>
												</div>
											</div>
										</div>
									</v-card-title>
									<!-- Form -->
									<template v-if="loading">
										<div class="row">
											<v-skeleton-loader
												width="100%"
												loading
												v-bind="skeletonLoader"
												type="card-avatar, article, actions"
											></v-skeleton-loader>
											<div class="col-3 text-right">
											<v-btn
												color="primary"
												class="mr-4 w-full"
												@click="testButton"
											>
												Test
											</v-btn>
										</div>
										</div>
									</template>
									<template v-else>
										<div class="p-5">
											<!-- User Image Row-->

											<div class="row">
												<div class="header">
													Account Details
												</div>
											</div>											
											<div class="row">
												<div class="col-4">
													<div class="py-3">
														<div v-if="Auth.fullUserProfile.profile_img">
															<v-img :src="Auth.fullUserProfile.profile_img" max-width="150" max-height="150" alt="Profile"> </v-img>
														</div>
														<div v-else>
															<v-img src="@/assets/static/UserProfile600x600.png" max-width="150" max-height="150" alt="Profile"> </v-img>
														</div>

													</div>
												</div>
												<div class="col-8">
													<div v-if="userData.merchant_obj" class="row">
														<div class="col-6">
															<div class="row">
																<div class="field-title">
																	Merchant Name
																</div>
																<div class="field-content">
																	{{ userData.merchant_obj.legal_name }}
																</div>
															</div>
															<div class="row mt-2">
																<div class="field-title">
																	Account Type
																</div>
																<div class="field-content">
																	Employee
																</div>
															</div>
														</div>
														<div class="col-6">

															<div class="row mt-2">
																<div class="field-title">
																	Hosted Payment Page
																</div>
																<div class="field-content">
																	https://{{userData.merchant_obj.domain}}.patientpaymentportal.com/payment
																</div>
															</div>
															<div class="row mt-2">
																<div class="field-title">
																	Primary Account Email
																</div>
																<div class="field-content">
																	{{ userData.merchant_obj.primary_email }}
																</div>
															</div>
														</div>
													</div>

													<div v-else class="row">
														<div class="col-6">
															<div class="row">
																<div class="field-title">
																	Merchant Name
																</div>
																<div class="field-content">
																	None 
																</div>
															</div>
															<div class="row mt-2">
																<div class="field-title">
																	Account Type
																</div>
																<div class="field-content">
																	Superuser
																</div>
															</div>
														</div>
														<div class="col-6">
															<div class="row mt-2">
																<div class="field-title">
																	Hosted Payment Page
																</div>
																<div class="field-content">
																	None Available
																</div>
															</div>
															
														</div>
													</div>
													
												</div>
											</div>

											<div class="row">
												<div class="header">
													Personal Details
												</div>
											</div>
											<div class="row">
												<div class="col-6">
													<div class="field-title">
														First Name
													</div>
													<div class="field-content">
														{{ userData.user_obj.first_name }}
													</div>
												</div>
												<div class="col-6">
													<div class="field-title">
														Last Name
													</div>
													<div class="field-content">
														{{ userData.user_obj.last_name }}
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-12">
													<div class="field-title">
														Email Address
													</div>
													<div class="field-content">
														{{ userData.user_obj.email }}
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-6">
													<div class="field-title">
														Phone
													</div>
													<div class="field-content">
														{{ userData.user_obj.mobile_phone }}
													</div>
												</div>
												<div class="col-6">
													<div class="field-title">
														Fax
													</div>
													<div class="field-content">
														{{ userData.user_obj.fax }}
													</div>
												</div>
											</div>
											<div class="row">
												<div class="header mt-5">
													Address Details
												</div>
											</div>
											<div class="row">
												<div class="col-8">
													<div class="field-title">
														Address
													</div>
													<div class="field-content">
														{{ userData.user_obj.address }}
													</div>
												</div>
												<div class="col-4">
													<div class="field-title">
														Address 2
													</div>
													<div class="field-content">
														{{ userData.user_obj.address2 }}
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-4">
													<div class="field-title">
														City
													</div>
													<div class="field-content">
														{{ userData.user_obj.city }}
													</div>
												</div>
												<div class="col-4">
													<div class="field-title">
														State
													</div>
													<div class="field-content">
														{{ userData.user_obj.state }}
													</div>
												</div>
												<div class="col-4">
													<div class="field-title">
														Zip Code
													</div>
													<div class="field-content">
													{{ userData.user_obj.zip_code }}
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-12">
													<div class="field-title">
														About Me
													</div>
													<div class="field-content">
														{{ userData.user_obj.bio }}
													</div>
												</div>
											</div>
						

										</div>
									</template>
									<!-- END Form -->
								</v-card>
								
							</template>
					
							
							<!-- Edit Form Fields -->
							<template v-else>
								<v-card>
									<v-card-title>
										<div class="row">
											<div class="col-9">
												<div class="title">
													Profile Details
												</div>
											</div>
											<div class="col-3 text-right">
												<div class="offset-header-buttons">
													<v-btn class="mx-2" fab dark color="accent" @click="editProfile = !editProfile">
														<v-icon dark>mdi-pencil</v-icon>
													</v-btn>
												</div>
											</div>
										</div>
									</v-card-title>
									<!-- Form -->
									<template v-if="loading">
										<div class="row">
											<v-skeleton-loader
												loading
												width="100%"
												v-bind="skeletonLoader"
												type="card-avatar, article, actions"
											></v-skeleton-loader>
										</div>
									</template>
									<template v-else>
										<div class="p-5">
											<v-form
												ref="userProfileFormRef"
												v-model="validForm"
											>
												<div class="row">
													<div class="header">
														Account Details
													</div>
												</div>											
												<div class="row">
													<div class="col-4">
														<v-avatar
															class="profile"
															size="164"
															tile
														>

															<v-hover v-slot="{ hover }">
																<div>
																	<div v-if="Auth.fullUserProfile.profile_img">
																		<v-img :src="Auth.fullUserProfile.profile_img" max-width="150" max-height="150" alt="Profile"> </v-img>
																	</div>
																	<div v-else>
																		<v-img src="@/assets/static/UserProfile600x600.png" max-width="150" max-height="150" alt="Profile"> </v-img>
																	</div>
																	
																	<v-expand-transition>
																		<v-overlay
																			v-if="hover"
																			:absolute="true">
																				<div
																					class="flex transition-fast-in-fast-out v-card--reveal"
																					style="height: 100%;"
																				>
																				<div class="row">
																					<v-btn @click="changeProfileImage" icon>
																						<v-icon large>
																							mdi-pencil
																						</v-icon>
																					</v-btn>
																				</div>
																				
																			</div>
																		</v-overlay>	
																	</v-expand-transition>

																</div>
															</v-hover>
														</v-avatar>

													</div>
													<div class="col-8">

														<div v-if="userData.merchant_obj" class="row">
															<div class="col-6">
																<div class="row">
																	<div class="field-title">
																		Merchant Name
																	</div>
																	<div class="field-content">
																		{{ userData.merchant_obj.legal_name }}
																	</div>
																</div>
																<div class="row mt-2">
																	<div class="field-title">
																		Account Type
																	</div>
																	<div class="field-content">
																		Employee
																	</div>
																</div>
															</div>
															<div class="col-6">

																<div class="row mt-2">
																	<div class="field-title">
																		Hosted Payment Page
																	</div>
																	<div class="field-content">
																		https://{{userData.merchant_obj.domain}}.patientpaymentportal.com/payment
																	</div>
																</div>
																<div class="row mt-2">
																	<div class="field-title">
																		Primary Account Email
																	</div>
																	<div class="field-content">
																		{{ userData.merchant_obj.primary_email }}
																	</div>
																</div>
															</div>
														</div>

														<div v-else class="row">
															<div class="col-6">
																<div class="row">
																	<div class="field-title">
																		Merchant Name
																	</div>
																	<div class="field-content">
																		None 
																	</div>
																</div>
																<div class="row mt-2">
																	<div class="field-title">
																		Account Type
																	</div>
																	<div class="field-content">
																		Superuser
																	</div>
																</div>
															</div>
															<div class="col-6">
																<div class="row mt-2">
																	<div class="field-title">
																		Hosted Payment Page
																	</div>
																	<div class="field-content">
																		None Available
																	</div>
																</div>
																
															</div>
														</div>
														
													</div>
												</div>

												<div class="row">
													<div class="header">
														Personal Details
													</div>
												</div>
												<div class="row">
													<div class="col-6">
														<v-text-field
															v-model="userData.user_obj.first_name"
															:rules="nameRules"
															label="First Name *"
														></v-text-field>
													</div>
													<div class="col-6">
														<v-text-field
															v-model="userData.user_obj.last_name"
															:rules="nameRules"
															label="Last Name *"
														></v-text-field>
													</div>
												</div>
												<div class="row">
													<div class="col-12">
														<v-text-field
															v-model="userData.user_obj.email"
															:rules="emailRules"
															label="E-mail *"
														></v-text-field>
													</div>
												</div>
												<div class="row">
													<div class="col-6">
														<v-text-field
															v-model="mobile_phone"
															:rules="phoneRules"
															label="Phone *"
															v-mask="'(###) ###-####'"
														></v-text-field>
													</div>
													<div class="col-6">
														<v-text-field
															v-model="userData.user_obj.fax"
															:rules="faxRules"
															v-mask="'(###) ###-####'"
															label="Fax"
														></v-text-field>
													</div>
												</div>
												<div class="row">
													<div class="header mt-5">
														Address Details
													</div>
												</div>
												<div class="row">
													<div class="col-8">
														<v-text-field
															v-model="userData.user_obj.address"
															:rules="requiredRule"
															label="Address *"
														></v-text-field>
													</div>
													<div class="col-4">
														<v-text-field
															v-model="userData.user_obj.address2"
															label="Address 2"
														></v-text-field>
													</div>
												</div>
												<div class="row">
													<div class="col-4">
														<v-text-field
															v-model="userData.user_obj.city"
															:rules="requiredRule"
															label="City *"
														></v-text-field>
													</div>
													<div class="col-4">
														<v-text-field
															v-model="userData.user_obj.state"
															label="State *"
															:rules="requiredRule"
															v-mask="'AA'"
														></v-text-field>
													</div>
													<div class="col-4">
														<v-text-field
															v-model="userData.user_obj.zip_code"
															label="Zip Code *"
															:rules="requiredRule"
															v-mask="'#####'"
														></v-text-field>
													</div>
												</div>
												<div class="row">
													<div class="col-12">
														<v-textarea
														label="Notes"
															name="Acocunt Notes"
															v-model="userData.user_obj.bio"
														></v-textarea>
													</div>
												</div>
											</v-form>
											<div class="row justify-end mt-6">
												<div class="col-3 text-right">
													<v-btn
														width="100%"
														color="error"
														class="mr-4"
														@click="testButton"
													>
														Test
													</v-btn>
												</div>
												<div class="col-3 text-right">
													<v-btn
														width="100%"
														color="error"
														class="mr-4"
														@click="back"
													>
														Back
													</v-btn>
												</div>
												<div class="col-3 text-right">
													<v-btn
														width="100%"
														color="success"
														class="mr-4"
														@click="submitForm"
													>
														Submit
													</v-btn>
												</div>
												<!-- <div v-if="$can('view', 'superuser')" class="col-3 text-right">
													<v-btn
														color="primary"
														class="mr-4 w-full"
														@click="testButton"
													>
														Test
													</v-btn>
												</div> -->
											</div>

										</div>
									</template>
									<!-- END Form -->
								</v-card>
							</template>
						</DxScrollView>
					</div>
					<!-- END Main Content Area -->

				</div>
				<!-- END Main Container Row -->
		</div>
		<!-- Modals -->

		<template>
			<div>
				<v-overlay 
					:value="openModal">
					<v-sheet 
						id="v-sheet"
						light
						:min-width="500"
						elevation="2"
						:height="300"
						class="text-center mx-auto v-sheet">
						
						<div class="row bg-primary">
							<div class="col-9 text-left flex items-center px-5">
								<div class="headline-5 text-white">
									Upload Image
								</div>
							</div>
							<div class="col-3 h-16 flex items-center justify-end pr-5">
								<v-btn
									icon
									color="white"
									@click="openModal = false"
								>
									<v-icon size="60">mdi-close-circle-outline</v-icon>
								</v-btn>
							</div>
						</div>

						<div class="row">
							<div 	
								class="p-4 w-full"
								width="100%">
								<DxScrollView
									show-scrollbar="always"
									:height="150"
									width="100%">
									<div class="row">
										<div class="field-title">
											Select Image File
										</div>
									</div>
									
									<div class="row">
										<div class="col-12">
											<v-file-input
												accept="image/*"
												label="File input"
												v-model="profile_img"
											></v-file-input>
										</div>
									</div>
									
								</DxScrollView>
							</div>
						</div>


						<div class="row border-t-4 border-gray-400">
							<div class="row justify-end mt-2">
								<div class="col-3">
									<v-btn
										@click="saveProfileImage"
										color="primary">
										Save
									</v-btn>
								</div>
							</div>
						</div>
							

					</v-sheet>
					
				</v-overlay>
				
			</div>


		</template>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

// Devexpress
import { DxScrollView } from 'devextreme-vue/scroll-view'

// Mixins
import { FormMixins } from '@/mixins/form-mixins'

//Components
import modalComponents from "@/components/popups/modal-component"

export default {
  name: 'profileComponent',
  components: {
		DxScrollView,
		modalComponents
  },
  mixins: [
    FormMixins
  ],
  props: {
		userSettings: {
			type: Object,
			required: true
		},

  },
  data () {
    return {
      // Main Data
			editProfile: false,
			loading: true,
			openModal: false,
			profile_img: null,
			validForm: false,

			//Form Data
			mobile_phone: null,

			userData: {},
			skeletonLoader: {
				class: 'mb-6',
        boilerplate: true,
        elevation: 2,
			}


    }
  },
  methods: {
    testButton () {
			console.log("userData", this.userData)
		},
		
		changeProfileImage() {
			console.log("changeProfileImage")
			this.openModal = true
		},
		saveProfileImage() {
			if(this.profile_img) {
				var profileReader = new FileReader();
				profileReader.readAsDataURL(this.profile_img);
				profileReader.onload = (e) => {
					this.userData.profile_img = e.target.result;
					if(this.GET_FULL_PROFILE.user_obj.is_superuser) {
						this.$store.dispatch("PATCHSuperuser", this.userData)
					} else {
						this.$store.dispatch("PATCHEmployee", this.userData)
					}
				};
			} 
			this.openModal = false
		},

		submitForm() {
			if(this.mobile_phone) {
				this.userData.user_obj.mobile_phone = this.mobile_phone.replace(/\D/g,'')
			}
			let valid = this.$refs.userProfileFormRef.validate()
			if(valid) {
				//Set Image
				if(this.profile_img) {
					var profileReader = new FileReader();
					profileReader.readAsDataURL(this.profile_img);
					profileReader.onload = (e) => {
						this.userData.profile_img = e.target.result;
						this.makePATCHRequest(this.userData)
					};
				} else {
					this.makePATCHRequest(this.userData)
				}
					

				
			}
			
		},
		makePATCHRequest(userdata) {
			return new Promise (async (resolve, reject) => {
				try {
					var userObj = JSON.parse(JSON.stringify(userdata))
					if(this.GET_FULL_PROFILE.user_obj.is_superuser) {
						//update userBase First
						let response = await this.$store.dispatch('PATCHBaseUser', this.userData.user_obj);
						if(response.status === 200 || response.status === 201 ) {
							//Update SU Instance
						
							let SU = await this.$store.dispatch("PATCHSuperuser", userdata)

							return resolve(SU)
						}
					

					} else {
						//update userBase First
						let response = await this.$store.dispatch('PATCHBaseUser', this.userData.user_obj);
						if(response.status === 200 || response.status === 201 ) {
							//Update EE Instance
							
							userdata.profile_img = null
							let EE = await this.$store.dispatch("PATCHEmployee", userdata)
							userdata.user_obj.profile_img = userObj.user_obj.profile_img

							return resolve(EE)
						}
					}
				}catch(error) {
					console.error('error', error)
					return reject()
				}				
			})
		},
		back() {
			this.$router.go(-1)
		},


  },
  computed: {
    ...mapState(["Auth"]),
		...mapGetters(["GET_FULL_PROFILE"]),
		listData() {
			return this.$store.getters.GET_FULL_PROFILE
		}
},
	watch: {
		listData(newVal, oldVal) {
			this.userData = this.listData
			this.loading = false
		}
	},
	created() {
		if(Object.keys(this.listData).length != 0) {
			this.userData = this.listData
			this.loading = false
			this.mobile_phone = this.userData.user_obj.mobile_phone
		}
		
	},
  mounted () {
	}

}
</script>
<style scoped lang="scss">

</style>
